import axios from 'axios'
import { defineStore } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useGlobalStore } from '@/stores/global'
import { ref, computed } from 'vue'

export const useUserDataStore = defineStore(
   'userdata',
   () => {

      const cache = ref({
         version: 0,
         options_version: 0,
         mainmenu_version: 0,
         usermenu_version: 0,
         doktree_version: 0,
         orgatree_version: 0,
         dirty: false,
         unsaved: false,
         created_at: new Date().getTime(),
         updated_at: new Date().getTime(),
      })

      const dashboard = ref([
         { component : 'MeinProfil', name: 'Mein Profil', order : '1', span: 1,  display: true },
         { component : 'MeineHunde', name: 'Meine Hunde', order : '2', span: 1, display: true },
         { component : 'MeineZuchtstaette', name: 'Meine Zuchtstätte', order : '3', span: 1, display: true },
         { component : 'MeineWuerfe', name: 'Meine Würfe', order : '4', span: 1, display: true },
         { component : 'MeineVeranstaltungen', name: 'Meine Veranstaltungen', order : '5', span: 1, display: true },
         { component : 'MeineDokumente', name: 'Meine Dokumente', order : '6', span: 1, display: true },
         { component : 'MeineAufgaben', name: 'Meine Aufgaben', order : '7', span: 1, display: true },
         { component : 'AktuelleNachrichten', name: 'Aktuelle Nachrichten', order : '8', span: 1, display: true },
         { component : 'Einstellungen', name: 'Einstellungen', order : '9', span: 1, display: true },
   ]);


//    const dashboard = ref({
//       'meinprofil': { name: 'Mein Profil', order : '1', display: true },
//       'meinehunde': { name: 'Meine Hunde', order : '2', display: true },
//       'meinezuchtstaette': { name: 'Meine Zuchtstätte', order : '3', display: true },
//       'meinewuerfe': { name: 'Meine Würfe', order : '4', display: true },
//       'meineveranstaltungen': { name: 'Meine Veranstaltungen', order : '5', display: true },
//       'meinedokumente': { name: 'Meine Dokumente', order : '6', display: true },
//       'meineaufgaben': { name: 'Meine Aufgaben', order : '7', display: true },
//       'aktuellenachrichten': { name: 'Aktuelle Nachrichten', order : '8', display: true },
//       'einstellungen': { name: 'Einstellungen', order : '9', display: true },
// });

      const funktionen = ref(null)
      const hunde = ref(null)
      const listen = ref(null)
      const mitglied = ref(null)
      const dokumente = ref(null)
      const zwinger = ref(null)
      const person = ref(null)
      const hundanlageantraege = ref(null)



      function $reset() {
         hunde.value = null,
         listen.value = null,
         mitglied.value = null,
         dokumente.value = null,
         zwinger.value = null,
         person.value = null,
         hundanlageantraege.value = null,
         cache.value = {
            version: 0,
            dirty: false,
            unsaved: false,
            created_at: new Date().getTime(),
            updated_at: new Date().getTime(),
         }
         dashboard.value = [     
            { id : 'meinprofil', name: 'Mein Profil', order : '1', display: true },
            { id : 'meinehunde', name: 'Meine Hunde', order : '2', display: true },
            { id : 'meinezuchtstaette', name: 'Meine Zuchtstätte', order : '3', display: true },
            { id : 'meinewuerfe', name: 'Meine Würfe', order : '4', display: true },
            { id : 'meineveranstaltungen', name: 'Meine Veranstaltungen', order : '5', display: true },
            { id : 'meinedokumente', name: 'Meine Dokumente', order : '6', display: true },
            { id : 'meineaufgaben', name: 'Meine Aufgaben', order : '7', display: true },
            { id : 'aktuellenachrichten', name: 'Aktuelle Nachrichten', order : '8', display: true },
            { id : 'einstellungen', name: 'Einstellungen', order : '9', display: true },
      ]
      }

      const zuchthunde = computed(() => {
         return hunde.value.filter((hund) => {
            return hund.zuchthund == 1
         })
      })

      async function getUserData() {
         const global = useGlobalStore()

         await axios.get('/api/person/daten').then((response) => {
            console.log('getUserdata')
            funktionen.value = response.data.funktionen;
            hunde.value = response.data.hunde;
            listen.value = response.data.listen;
            mitglied.value = response.data.mitglied;
            zwinger.value = response.data.zwinger;
            person.value = response.data.person;
            dokumente.value = response.data.dokumente;
            hundanlageantraege.value = response.data.hundanlageantraege;
            global.loadingMeineDaten = false
         })
      }

      async function getBereicheHund( id , bereiche ) {

         let formData = new FormData();

         // for (var i = 0; i < bereiche.length; i++) {
         //    formData.append('bereiche[]', bereiche[i]);
         //  }

          bereiche.forEach( (e) => {
            formData.append('bereiche[]', e)
          })

         formData.append("id", id);

         await axios.post('/api/hund/bereiche/'+id, formData).then((response) => {

            const index = hunde.value.findIndex((hund) => hund.id == id);
            console.log('getBereicheHund', index)
            console.log(hunde.value[index])
            Object.assign(hunde.value[index], response.data);
            console.log(hunde.value[index])
          //   global.loadingMeineDaten = false
         })
      }

      function setHundebilder(e) {
         console.log(e)
         this.hunde[e.index].images = e.images
      }

      function setZwingerbilder(e) {
         console.log(e)
         this.zwinger.images = e.images
      }


      return { $reset, cache, funktionen, hunde, listen, mitglied, dokumente, zwinger, person, zuchthunde, hundanlageantraege, dashboard, getUserData, getBereicheHund, setHundebilder, setZwingerbilder }
   },
   {
      persist: true,
   }
)
