<template>
   <div class="flex flex-col justify-start px-5 desktop:mx-5 my-5 bg-drc-grau-500 rounded pt-2 pb-5 border border-drc-grau-400 shadow-lg"
   :class="{ 'bg-drc-orange bg-opacity-30' : bugtrack.page.id != page_id && page_id != 0 }">
      <div class="flex">
         <img v-if="auth.user != null" :src="bugtrack.photo" :alt="bugtrack.author" class="h-12 w-12 object-cover mr-4 transition-all duration-150 ease-in-out rounded-full ring-1 ring-drc-grau-400 my-2" />
         <div class="flex-grow flex flex-col">
            <div class="flex justify-start items-center w-full pl-3 flex-wrap">
               <div class="flex justify-start items-center py-2 flex-grow">
                  <div class="text-drc-gruen-300 text-sm font-bold mr-4"> {{ bugtrack.author }} </div>
                  <div class="text-drc-grau-300 text-sm">{{ bugtrack.created_at }}</div>
                  <div v-if="
                     bugtrack.created_at != bugtrack.updated_at &&
                     (bugtrack.updated_at != '' || bugtrack.updated_at != null)
                     " class="text-drc-grau-300 text-sm italic"> &nbsp;&nbsp;(bearbeitet: {{ bugtrack.updated_at }}) 
                  </div>
               </div>
               
               <b-select-multi-small v-if="showSelectPageID || page_id == 0" type="text" v-model:value="bugtrack.page" @select="onSelectPageId($event)" :options="options.bugtracks" searchable class="text-xs !w-100 " />
               <span v-if="(bugtrack.page.id != page_id) && (page_id != 0)" class="text-drc-grau-300 text-xs text-right">(verschoben)</span>
               <button class="text-drc-marker-neutral w-8 hover:text-drc-orange  flex items-center justify-center" 
                  @click="showSelectPageID = !showSelectPageID">
                  <svg-externer-link class="w-8 h-8" />
               </button>
            </div>
            <div class="flex items-start">
               <div class="flex flex-col justify-start items-start w-full">
                  <b-textarea-autoresize class="text-sm h-24 w-full px-3 py-2 text-gray-700 rounded focus:border-none focus:bg-white focus:ring focus:ring-drc-gelb" :class="{ 'bg-white text-black': disabled }" placeholder="Neue Nachricht eingeben" id="newnotice" name="newnotice" :disabled="disabled" :value="bugtrack.text" @update:value="
                bugtrack.text = $event;
                changed = true;
              " @focus="focus = true" min-height="2" max-height="1000"></b-textarea-autoresize>
               </div>
               <div class="flex flex-col items-center justify-center">
                  <div class="flex items-start justify-center">
                     <div class="felx flex-col">
                     <button class="bg-drc-gruen-300 text-white w-32 ml-2 text-sm rounded hover:text-drc-orange border h-8  flex items-center justify-center" :class="{ '!bg-drc-marker-rot': !bugtrack.closed }" v-if="!disabled" @click="toggleClose()">
                        <span v-if="bugtrack.closed">erledigt</span>
                        <span v-else>offen</span>
                     </button>
                     <a v-if="bugtrack.url" class="text-sm bg-drc-gruen-300 text-white w-32 ml-2 rounded hover:text-drc-orange border h-8 flex items-center justify-center" :href="bugtrack.url" target="_blank">Link</a>
                     </div>
                     <!-- <button class="text-drc-gruen-300 w-8 hover:text-drc-orange ml-2 flex items-center justify-center"  @click="showSelectPageID = !showSelectPageID">
                        <svg-externer-link class="w-8 h-8" />
                     </button> -->
                     <button class="text-drc-marker-rot w-8 hover:text-drc-orange  flex items-center justify-center" v-if="!disabled" @click="del()">
                        <svg-loeschen class="w-8 h-8" />
                     </button>
                  </div>
               </div>
            </div>
            <button class="text-white bg-drc-gruen-300 w-28 ml-3 rounded h-8 mt-1 text-sm hover:bg-drc-orange" v-if="!disabled && changed" @click="update()"> Speichern </button>
         </div>
      </div>
      
      <!-- <div class="px-3 py-3 mb-10 text-gray-700 rounded min-w-75 bg-white text-sm text-left" v-html="divtext" /> -->
   </div>
</template>
<script setup>
import axios from "axios";
import { ref, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useGlobalStore } from "@/stores/global";
import { useHundStore } from "@/stores/hund";
import { storeToRefs } from "pinia";

import BButton from "@/components/global/buttons/BButton.vue";
import BCheckboxSolo from "@/components/global/checkboxes/BCheckboxSolo.vue";
import BTextareaAutoresize from "@/components/global/inputs/BTextareaAutoresize.vue";
import BInput from "@/components/global/inputs/BInput.vue";
import BTrenner from "@/components/global/BTrenner.vue";
import BSelectMultiSmall from "@/components/global/select/BSelectMultiSmall.vue";
import BSelectMultiSolo from "@/components/global/select/BSelectMultiSolo.vue";

const auth = useAuthStore();

const { options } = useGlobalStore();


const emits = defineEmits(["delete"]);

const props = defineProps({
   index: {
      type: Number,
   },
   id: {
      type: Number,
   },
   bugtrack: {
      type: Object,
   },
   page_id: {
      type: Number,
   },
});

const showSelectPageID = ref(false);
const focus = ref(false);
const changed = ref(false);

const disabled = computed(() => {
   return false;
  //  return props.bugtrack.user_id == auth.user.id ? false : true;
});

const divtext = computed(() => {
   if (props.bugtrack.text)
      return props.bugtrack.text
         .replace(/\r?\n/g, "<br/>")
         .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;");
});

function update(e) {
   axios.put("api/bugtrack/" + props.bugtrack.id, props.bugtrack).then((response) => {
      console.log(response.data);
      if (response.data.bugtrack.id) {
         props.bugtrack.updated_at = response.data.bugtrack.updated_at;
         changed.value = false;
         //    id: response.data.bugtrack.id,
         // })
         // newNotiz.value = ''
         // snackbar.add({ type: 'success', text: response.data.success })
      }
   });
}

function onSelectPageId(e) {
   console.log('page', e.id);
   props.bugtrack.page_id = e.id;
   props.bugtrack.page_name = e.name;
   axios.put("api/bugtrack/" + props.bugtrack.id, props.bugtrack).then((response) => {
      console.log(response.data);
      if (response.data.bugtrack.id) {
         props.bugtrack.updated_at = response.data.bugtrack.updated_at;
         changed.value = false;
         //    id: response.data.bugtrack.id,
         // })
         // newNotiz.value = ''
         // snackbar.add({ type: 'success', text: response.data.success })
      }
   });
}

function del(e) {
   axios.delete("api/bugtrack/" + props.bugtrack.id).then((response) => {
      props.bugtrack.text = "gelöscht";
      emits("delete");

      if (response.data.bugtrack.id) {
         props.bugtrack.text = "gelöscht";
         //  emit('delete')
         //    id: response.data.bugtrack.id,
         // })
         // newNotiz.value = ''
         // snackbar.add({ type: 'success', text: response.data.success })
      }
   });
}

function toggleClose(e) {
   props.bugtrack.closed = !props.bugtrack.closed;
   axios.put("api/bugtrack/" + props.bugtrack.id, props.bugtrack).then((response) => {
      console.log(response.data);
      if (response.data.bugtrack.id) {
         props.bugtrack.updated_at = response.data.bugtrack.updated_at;
         changed.value = false;
         //    id: response.data.bugtrack.id,
         // })
         // newNotiz.value = ''
         // snackbar.add({ type: 'success', text: response.data.success })
      }
   });
}
</script>
<style lang="scss"></style>
