import herz from './../assets/svg/01_Herz.svg'
import hund from './../assets/svg/02_Hund.svg'
import derverein from './../assets/svg/03_Der Verein.svg'
import Mitglieder from './../assets/svg/04_Mitglieder.svg'
import schloss from './../assets/svg/05_Schloss.svg'
import rassen from './../assets/svg/06_Rassen.svg'
import hobby from './../assets/svg/07_Hobby.svg'
import zucht from './../assets/svg/10_Zucht.svg'
import lupe from './../assets/svg/09_Lupe.svg'
import gesundheit from './../assets/svg/11_Gesundheit.svg'
import wiruberuns from './../assets/svg/12_Wir_über_uns.svg'
import unserezieleaufgaben from './../assets/svg/13_Unsere_Ziele - Aufgaben.svg'
import vereinsleben from './../assets/svg/14_Vereinsleben.svg'
import drcinside from './../assets/svg/15_DRC_inside.svg'
import termine from './../assets/svg/16_Termine.svg'
import datenverwaltung from './../assets/svg/17_Datenverwaltung.svg'
import wichtig from './../assets/svg/18_Wichtig.svg'
import rassebeschreibung from './../assets/svg/19_Rassebeschreibung.svg'
import intrassesstandart from './../assets/svg/20_ Int. Rassesstandart.svg'
import bildergalerie from './../assets/svg/21_Bildergalerie.svg'
import pdf from './../assets/svg/22_PDF.svg'
import tatze from './../assets/svg/23_Tatze.svg'
import schauwesen from './../assets/svg/24_Schauwesen.svg'
import dasleistungswesen from './../assets/svg/25_Das Leistungswesen.svg'
import jagdarbeit from './../assets/svg/26_Jagd. Arbeit.svg'
import retrieverarbeitausserhalbdesdrc from './../assets/svg/27_Retrieverarbeit außerhalb des DRC.svg'
import zuechter from './../assets/svg/28_Zuechter.svg'
import mutter from './../assets/svg/30_Mutter.svg'
import award from './../assets/svg/31_Award.svg'
import map from './../assets/svg/32_Map.svg'
import dokument from './../assets/svg/33_Dokument.svg'
import dna from './../assets/svg/34_DNA.svg'
import arzt from './../assets/svg/35_Arzt.svg'
import doc from './../assets/svg/36_DOC.svg'
import kontakt from './../assets/svg/37_Kontakt.svg'
import absenden from './../assets/svg/38_Absenden.svg'
import gehirn from './../assets/svg/39_Gehirn.svg'
import organ from './../assets/svg/41_Organ.svg'
import hr from './../assets/svg/42_H-R.svg'
import bakterie from './../assets/svg/40_Bakterie.svg'
import vater from './../assets/svg/29_Vater.svg'
import welpe from './../assets/svg/44_Welpe.svg'
import ordnungen from './../assets/svg/45_Ordnungen.svg'
import formulare from './../assets/svg/46_Formulare.svg'
import gelenk from './../assets/svg/43_Gelenk.svg'
import wurf from './../assets/svg/47_Wurf.svg'
import ahnentafel from './../assets/svg/48_Ahnentafel.svg'
import tabelle from './../assets/svg/49_Tabelle.svg'
import keinzugang from './../assets/svg/50_Kein Zugang.svg'
import zuechtersuche from './../assets/svg/51_Zuechtersuche.svg'
import deckdatum from './../assets/svg/52_Deckdatum.svg'
import wurfdatum from './../assets/svg/53_Wurfdatum.svg'
import web from './../assets/svg/54_Web.svg'
import telefon from './../assets/svg/55_Telefon.svg'
import mail from './../assets/svg/56_Mail.svg'
import externerlink from './../assets/svg/57_Externer Link.svg'
import glocke from './../assets/svg/58_Glocke.svg'
import meinprofil from './../assets/svg/59_Mein Profil.svg'
import meinehunde from './../assets/svg/60_Meine Hunde.svg'
import meinetermine from './../assets/svg/61_Meine Termine.svg'
import meinedokumente from './../assets/svg/62_Meine Dokumente.svg'
import einstellungen from './../assets/svg/63_Einstellungen.svg'
import bearbeiten from './../assets/svg/64_Bearbeiten.svg'
import loeschen from './../assets/svg/65_Loeschen.svg'
import aktualisieren from './../assets/svg/66_Aktualisieren.svg'
import exportieren from './../assets/svg/67_Exportieren.svg'
import filter from './../assets/svg/68_Filter.svg'
import drucken from './../assets/svg/70_Drucken.svg'
import spaltenansicht from './../assets/svg/69_Spaltenansicht.svg'
import reset from './../assets/svg/71_Reset.svg'
import upload from './../assets/svg/73_Upload.svg'
import speicherncloud from './../assets/svg/72_Speichern - Cloud.svg'
import download from './../assets/svg/74_Download.svg'
import meindashboard from './../assets/svg/75_Mein Dashboard.svg'
import status from './../assets/svg/76_Status.svg'
import personen from './../assets/svg/77_Personen.svg'
import finanzenbank from './../assets/svg/78_Finanzen - Bank.svg'
import funktionstraeger from './../assets/svg/79_Funktionstraeger.svg'
import exporte from './../assets/svg/80_Exporte.svg'
import reportings from './../assets/svg/81_Reportings.svg'
import administration from './../assets/svg/82_Administration.svg'
import gentest from './../assets/svg/84_Gentests.svg'
import augenuntersuchung from './../assets/svg/85_Augenuntersuchungen.svg'
import retrieverarbeit from './../assets/svg/83_retrieverarbeit.svg'
import hund2 from './../assets/svg/86_Hund.svg'
import hundeakte from './../assets/svg/hundeakte.svg'
import veranstaltungsakte from './../assets/svg/veranstaltungsakte.svg'
import favorite from './../assets/svg/favorite.svg'
import favoritehund from './../assets/svg/favorite-hund.svg'
import favoritezucht from './../assets/svg/favorite-zucht.svg'
import favoriteveranstaltung from './../assets/svg/favorite-veranstaltung.svg'
import favoritesolid from './../assets/svg/favorite-solid.svg'
import chevrondoubleright from './../assets/svg/chevron-double-right.svg'
import center from './../assets/svg/center.svg'
import chevrondown from './../assets/svg/chevron-down.svg'
import check from './../assets/svg/check.svg'
import chevronleft from './../assets/svg/chevron-left.svg'
import chevronright from './../assets/svg/chevron-right.svg'
import chevronup from './../assets/svg/chevron-up.svg'
import circlestencil from './../assets/svg/circle-stencil.svg'
import fliphorizontal from './../assets/svg/flip-horizontal.svg'
import flipvertical from './../assets/svg/flip-vertical.svg'
import handler from './../assets/svg/handler.svg'
import link from './../assets/svg/link.svg'
import minus from './../assets/svg/minus.svg'
import plus from './../assets/svg/plus.svg'
import rectanglestencil from './../assets/svg/rectangle-stencil.svg'
import resizehorizontal from './../assets/svg/resize-horizontal.svg'
import resizemaximize from './../assets/svg/resize-maximize.svg'
import rotateleft from './../assets/svg/rotate-left.svg'
import rotateright from './../assets/svg/rotate-right.svg'
import checked from './../assets/svg/checked.svg'
import resizereduce from './../assets/svg/resize-reduce.svg'
import resizevertical from './../assets/svg/resize-vertical.svg'
import resize from './../assets/svg/resize.svg'
import x from './../assets/svg/x.svg'
import zoomin from './../assets/svg/zoom-in.svg'
import zoomout from './../assets/svg/zoom-out.svg'
import upload2 from './../assets/svg/upload-2.svg'
import bars2 from './../assets/svg/bars-2.svg'
import barsarrowdown from './../assets/svg/bars-arrow-down.svg'
import barsarrowup from './../assets/svg/bars-arrow-up.svg'
import chevronupdown from './../assets/svg/chevron-up-down.svg'
import ellipsishorizontal from './../assets/svg/ellipsis-horizontal.svg'
import ellipsisvertical from './../assets/svg/ellipsis-vertical.svg'
import eye from './../assets/svg/eye.svg'
import eyeslash from './../assets/svg/eye-slash.svg'
import userminus from './../assets/svg/user-minus.svg'
import userplus from './../assets/svg/user-plus.svg'
import welpen from './../assets/svg/08_Welpen.svg'
import geldscheine from './../assets/svg/geldscheine.svg'
import chatbubblevoll from './../assets/svg/chatbubble-voll.svg'
import chatbubbleleer from './../assets/svg/chatbubble-leer.svg'
import handstop from './../assets/svg/hand-stop.svg'
import kreditkarte from './../assets/svg/kreditkarte.svg'
import pokal from './../assets/svg/pokal.svg'
import megaphone from './../assets/svg/megaphone.svg'
import schlosszu from './../assets/svg/schloss-zu.svg'
import schlossoffen from './../assets/svg/schloss-offen.svg'
import atsymbol from './../assets/svg/at-symbol.svg'
import gebiss from './../assets/svg/gebiss.svg'
import zahn from './../assets/svg/zahn2.svg'
import anmeldung from './../assets/svg/90_Anmeldung.svg'
import IconLogo from './../assets/icons/png/logo.png'
import ControlArrow from './../assets/controls/svg/arrow-ico.svg'
import chevrondoubledown from './../assets/svg/chevron-double-down.svg'
import chevrondoubleup from './../assets/svg/chevron-double-up.svg'
import clock from './../assets/svg/clock.svg'
import statsbar from './../assets/svg/stats-bar.svg'
import statspie from './../assets/svg/stats-pie.svg'
import zwingerakte from './../assets/svg/zwingerakte.svg'
import wurfakte from './../assets/svg/wurfakte.svg'
import favoriteperson from './../assets/svg/favorite-person.svg'
import email from './../assets/svg/88_EMail.svg'
import euro from './../assets/svg/89_Euro.svg'
import euro_ok from './../assets/svg/89_Euro_ok.svg'
import euro_not_ok from './../assets/svg/89_Euro_not_ok.svg'
import frage from './../assets/svg/91_Frage.svg'
import hundnotok from './../assets/svg/93_Hund_not_ok.svg'
import hundok from './../assets/svg/92_Hund_ok.svg'
import info from './../assets/svg/94_Info.svg'
import jagdscheinja from './../assets/svg/95_Jagdschein_ja.svg'
import jagdscheinnein from './../assets/svg/96_Jagdschein_nein.svg'
import teilnehmerok from './../assets/svg/97_Teilnehmer_ok.svg'
import teilnehmernotok from './../assets/svg/98_Teilnehmer_not_ok.svg'
import cursordragdrop from './../assets/svg/cursor-drag-drop.svg'
import dragdrop from './../assets/svg/drag-drop.svg'
import decrease from './../assets/svg/decrease.svg'
import fullscreen from './../assets/svg/fullscreen.svg'
import savefloppy from './../assets/svg/save-floppy.svg'
import attachfile from './../assets/svg/attach-file.svg'
import daumenhoch from './../assets/svg/daumen-hoch.svg'
import daumenrunter from './../assets/svg/daumen-runter.svg'
import fragezeichen from './../assets/svg/fragezeichen.svg'
import formularchecked from './../assets/svg/99_Formular_checked.svg'
import formularunchecked from './../assets/svg/100_Formular_unchecked.svg'
import checkboxchecked from './../assets/svg/checkbox_checked.svg'
import checkboxunchecked from './../assets/svg/checkbox_unchecked.svg'
import send from './../assets/svg/send.svg'
import backarrow from './../assets/svg/back-arrow.svg'
import dokumentminus from './../assets/svg/dokument-minus.svg'
import dokumentplus from './../assets/svg/dokument-plus.svg'
import lupeminus from './../assets/svg/lupe-minus.svg'
import lupeplus from './../assets/svg/lupe-plus.svg'
import lupedokument from './../assets/svg/lupe-dokument.svg'
import home from './../assets/svg/home.svg'
import phone from './../assets/svg/phone.svg'
import play from './../assets/svg/play.svg'
import calendardays from './../assets/svg/calendar-days.svg'
import calendarleer from './../assets/svg/calendar-leer.svg'
import arrowrectangleright from './../assets/svg/arrow-rectangle-right.svg'
import hundehuette from './../assets/svg/101_Hundehuette.svg'

// import markerblue from '@/assets/icons/map/marker-blue.png'
// import markerfav from '@/assets/icons/map/marker-fav.png'

export const registerSVGComponents = (app) => {

app.component('svg-herz', herz)
app.component('svg-hund', hund)
app.component('svg-der-verein', derverein)
app.component('svg-mitglieder', Mitglieder)
app.component('svg-schloss', schloss)
app.component('svg-rassen', rassen)
app.component('svg-hobby', hobby)
app.component('svg-zucht', zucht)
app.component('svg-lupe', lupe)
app.component('svg-gesundheit', gesundheit)
app.component('svg-wir-uber-uns', wiruberuns)
app.component('svg-unsere-ziele-aufgaben', unserezieleaufgaben)
app.component('svg-vereinsleben', vereinsleben)
app.component('svg-drc-inside', drcinside)
app.component('svg-termine', termine)
app.component('svg-datenverwaltung', datenverwaltung)
app.component('svg-wichtig', wichtig)
app.component('svg-rassebeschreibung', rassebeschreibung)
app.component('svg-int-rassesstandart', intrassesstandart)
app.component('svg-bildergalerie', bildergalerie)
app.component('svg-pdf', pdf)
app.component('svg-tatze', tatze)
app.component('svg-schauwesen', schauwesen)
app.component('svg-das-leistungswesen', dasleistungswesen)
app.component('svg-jagd-arbeit', jagdarbeit)
app.component('svg-retrieverarbeit-ausserhalb-des-drc', retrieverarbeitausserhalbdesdrc)
app.component('svg-zuechter', zuechter)
app.component('svg-mutter', mutter)
app.component('svg-award', award)
app.component('svg-map', map)
app.component('svg-dokument', dokument)
app.component('svg-dna', dna)
app.component('svg-arzt', arzt)
app.component('svg-doc', doc)
app.component('svg-kontakt', kontakt)
app.component('svg-absenden', absenden)
app.component('svg-gehirn', gehirn)
app.component('svg-organ', organ)
app.component('svg-h-r', hr)
app.component('svg-bakterie', bakterie)
app.component('svg-vater', vater)
app.component('svg-welpe', welpe)
app.component('svg-ordnungen', ordnungen)
app.component('svg-formulare', formulare)
app.component('svg-gelenk', gelenk)
app.component('svg-wurf', wurf)
app.component('svg-ahnentafel', ahnentafel)
app.component('svg-tabelle', tabelle)
app.component('svg-kein-zugang', keinzugang)
app.component('svg-zuechtersuche', zuechtersuche)
app.component('svg-deckdatum', deckdatum)
app.component('svg-wurfdatum', wurfdatum)
app.component('svg-web', web)
app.component('svg-telefon', telefon)
app.component('svg-mail', mail)
app.component('svg-externer-link', externerlink)
app.component('svg-glocke', glocke)
app.component('svg-mein-profil', meinprofil)
app.component('svg-meine-hunde', meinehunde)
app.component('svg-meine-termine', meinetermine)
app.component('svg-meine-dokumente', meinedokumente)
app.component('svg-einstellungen', einstellungen)
app.component('svg-bearbeiten', bearbeiten)
app.component('svg-loeschen', loeschen)
app.component('svg-aktualisieren', aktualisieren)
app.component('svg-exportieren', exportieren)
app.component('svg-filter', filter)
app.component('svg-drucken', drucken)
app.component('svg-spaltenansicht', spaltenansicht)
app.component('svg-reset', reset)
app.component('svg-upload', upload)
app.component('svg-speichern-cloud', speicherncloud)
app.component('svg-download', download)
app.component('svg-mein-dashboard', meindashboard)
app.component('svg-status', status)
app.component('svg-personen', personen)
app.component('svg-finanzen-bank', finanzenbank)
app.component('svg-funktionstraeger', funktionstraeger)
app.component('svg-exporte', exporte)
app.component('svg-reportings', reportings)
app.component('svg-administration', administration)
app.component('svg-gentest', gentest)
app.component('svg-augenuntersuchung', augenuntersuchung)
app.component('svg-retrieverarbeit', retrieverarbeit)
app.component('svg-hund2', hund2)
app.component('svg-hundeakte', hundeakte)
app.component('svg-veranstaltungsakte', veranstaltungsakte)
app.component('svg-favorite', favorite)
app.component('svg-favorite-hund', favoritehund)
app.component('svg-favorite-zucht', favoritezucht)
app.component('svg-favorite-veranstaltung', favoriteveranstaltung)
app.component('svg-favorite-solid', favoritesolid)
app.component('svg-chevron-double-right', chevrondoubleright)
app.component('svg-center', center)
app.component('svg-chevron-down', chevrondown)
app.component('svg-check', check)
app.component('svg-chevron-left', chevronleft)
app.component('svg-chevron-right', chevronright)
app.component('svg-chevron-up', chevronup)
app.component('svg-circle-stencil', circlestencil)
app.component('svg-flip-horizontal', fliphorizontal)
app.component('svg-flip-vertical', flipvertical)
app.component('svg-handler', handler)
app.component('svg-link', link)
app.component('svg-minus', minus)
app.component('svg-plus', plus)
app.component('svg-rectangle-stencil', rectanglestencil)
app.component('svg-resize-horizontal', resizehorizontal)
app.component('svg-resize-maximize', resizemaximize)
app.component('svg-rotate-left', rotateleft)
app.component('svg-rotate-right', rotateright)
app.component('svg-checked', checked)
app.component('svg-resize-reduce', resizereduce)
app.component('svg-resize-vertical', resizevertical)
app.component('svg-resize', resize)
app.component('svg-x', x)
app.component('svg-zoom-in', zoomin)
app.component('svg-zoom-out', zoomout)
app.component('svg-upload-2', upload2)
app.component('svg-bars-2', bars2)
app.component('svg-bars-arrow-down', barsarrowdown)
app.component('svg-bars-arrow-up', barsarrowup)
app.component('svg-chevron-up-down', chevronupdown)
app.component('svg-ellipsis-horizontal', ellipsishorizontal)
app.component('svg-ellipsis-vertical', ellipsisvertical)
app.component('svg-eye', eye)
app.component('svg-eye-slash', eyeslash)
app.component('svg-user-minus', userminus)
app.component('svg-user-plus', userplus)
app.component('svg-welpen', welpen)
app.component('svg-geldscheine', geldscheine)
app.component('svg-chatbubble-voll', chatbubblevoll)
app.component('svg-chatbubble-leer', chatbubbleleer)
app.component('svg-hand-stop', handstop)
app.component('svg-kreditkarte', kreditkarte)
app.component('svg-pokal', pokal)
app.component('svg-megaphone', megaphone)
app.component('svg-schloss-zu', schlosszu)
app.component('svg-schloss-offen', schlossoffen)
app.component('svg-at-symbol', atsymbol)
app.component('svg-gebiss', gebiss)
app.component('svg-zahn', zahn)
app.component('svg-anmeldung', anmeldung)
app.component('icon-logo', IconLogo)
app.component('control-arrow', ControlArrow)
app.component('svg-chevron-double-down', chevrondoubledown)
app.component('svg-chevron-double-up', chevrondoubleup)
app.component('svg-clock', clock)
app.component('svg-stats-bar', statsbar)
app.component('svg-stats-pie', statspie)
app.component('svg-zwingerakte', zwingerakte)
app.component('svg-wurfakte', wurfakte)
app.component('svg-favorite-person', favoriteperson)
app.component('svg-email', email )
app.component('svg-euro', euro )
app.component('svg-euro-ok', euro_ok )
app.component('svg-euro-not-ok', euro_not_ok )
app.component('svg-frage', frage )
app.component('svg-hund-not-ok', hundnotok )
app.component('svg-hund-ok', hundok )
app.component('svg-info', info )
app.component('svg-jagdschein-ja', jagdscheinja )
app.component('svg-jagdschein-nein', jagdscheinnein )
app.component('svg-teilnehmer-ok', teilnehmerok )
app.component('svg-teilnehmer-not-ok', teilnehmernotok )
app.component('svg-cursor-drag-drop', cursordragdrop )
app.component('svg-drag-drop', dragdrop )
app.component('svg-decrease', decrease )
app.component('svg-fullscreen', fullscreen )
app.component('svg-save-floppy', savefloppy )
app.component('svg-attach-file', attachfile )
app.component('svg-daumen-hoch', daumenhoch )
app.component('svg-daumen-runter', daumenrunter )
app.component('svg-fragezeichen', fragezeichen )
app.component('svg-formular-checked', formularchecked )
app.component('svg-formular-unchecked', formularunchecked )
app.component('svg-checkbox-checked', checkboxchecked )
app.component('svg-checkbox-unchecked', checkboxunchecked )
app.component('svg-send', send )
app.component('svg-back-arrow', backarrow )
app.component('svg-dokument-minus', dokumentminus )
app.component('svg-dokument-plus', dokumentplus )
app.component('svg-lupe-minus', lupeminus )
app.component('svg-lupe-plus', lupeplus )
app.component('svg-lupe-dokument', lupedokument )
app.component('svg-home', home )
app.component('svg-phone', phone )
app.component('svg-play', play )
app.component('svg-calendar-days', calendardays )
app.component('svg-calendar-leer', calendarleer )
app.component('svg-arrow-rectangle-right', arrowrectangleright )
app.component('svg-hundehuette', hundehuette )
}